<template>
  <ul class="icon-list">
    <li v-for="item in list" :key="item" @click="copyItem(item)">
      <span>
        <i :class="item"></i>
        <span class="icon-name">{{item}}</span>
      </span>
    </li>
  </ul>
</template>

<script>
import iconList from "./icon-list.js";
export default {
  props: ["isVisible"],
  data() {
    return {
      list: iconList
    };
  },
  methods: {
    copyItem(v) {
      this.$emit("chooseIcon", v);
    }
  }
};
</script>


<style lang="less" scoped>
.icon-list {
  overflow: hidden;
  list-style: none;
  padding: 0;
  border: 1px solid #eaeefb;
  border-radius: 4px;
  background: white;
  li {
    float: left;
    width: 16.66%;
    text-align: center;
    height: 120px;
    line-height: 120px;
    color: #666;
    font-size: 13px;
    transition: color 0.15s linear;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-right: -1px;
    margin-bottom: -1px;
    span {
      display: inline-block;
      line-height: normal;
      vertical-align: middle;
      font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
        Microsoft YaHei, SimSun, sans-serif;
      color: #99a9bf;
    }
    i {
      display: block;
      font-size: 32px;
      margin-bottom: 15px;
      color: #606266;
    }
    .icon-name {
      display: inline-block;
      padding: 0 3px;
      height: 1em;
      color: #606266;
    }
  }
}
</style>