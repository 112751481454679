<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-date"></i> {{$t('navbar.setting')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('navbar.permission')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div>
        <el-select clearable class="filter-item" style="width: 130px" v-model="queryData.type" :placeholder="$t('table.type')">
          <el-option v-for="item in typeOptions" :key="item.key" :label="item.value" :value="item.key">
          </el-option>
        </el-select>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleList">{{$t('table.query')}}</el-button>
        <el-button class="filter-item" style="margin-left: 10px;" @click="handleCreate" type="primary" icon="el-icon-edit">{{$t('table.add')}}</el-button>
      </div>
      <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>
        <el-table-column align="center" :label="$t('table.iName')">
          <template slot-scope="scope">
            <span>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('table.type')">
          <template slot-scope="scope">
            <span>{{permissionTypeFmt(scope.row.type)}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="URL">
          <template slot-scope="scope">
            <span>{{scope.row.url}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('table.method')">
          <template slot-scope="scope">
            <span>{{scope.row.method}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('table.weight')">
          <template slot-scope="scope">
            <span>{{scope.row.weight}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('table.status')">
          <template slot-scope="scope">
            <span :style="{color:scope.row.status==1?'#217bbd':'#e68a8a'}">{{scope.row.status | statusFmt}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('table.addTime')">
          <template slot-scope="scope">
            <span>{{scope.row.addTime}}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('table.operate')" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">{{$t('table.edit')}}</el-button>
            <el-button v-if="scope.row.status!='deleted'" size="mini" type="danger" @click="handleDel(scope.row, scope.$index)">{{$t('table.delete')}}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryData.page" :page-sizes="[10,20,30,50]" :page-size="queryData.rows" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>

      <el-dialog  :close-on-click-modal="false"  :title="$t('table.edit')" :visible.sync="dialogFormVisible" :before-close="closeDialog">
        <el-form ref="dataForm" :model="formData" label-position="left" :rules="rules" label-width="120px" style='width: 400px; margin-left:50px;'>
          <el-form-item :label="$t('table.type')" prop="type" style="white-space: nowrap;">
            <el-select v-model="formData.type" :placeholder="$t('table.choose')">
              <el-option v-for="item in typeOptions" :key="item.key" :label="item.value" :value="item.key">
              </el-option>
            </el-select>
            <el-checkbox v-show="formData.type == 3" style="margin-left:10px;" v-model="formData.addAuto">快速创建</el-checkbox>
          </el-form-item>
          <el-form-item :label="$t('table.iName')" prop="name">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.parentMenu')" prop="parentId" v-if="formData.type > 1">
            <el-select v-model="formData.parentId" :placeholder="$t('table.choose')">
              <el-option v-for="item in parentMap[formData.type-1]" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="URL" prop="url" v-if="formData.type == 2">
            <el-input v-model="formData.url"></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.method')" prop="method" v-if="formData.type == 3">
            <el-input v-model="formData.method"></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.icon')" prop="icon" v-if="formData.type == 1">
            <el-input v-model="formData.icon" readonly @focus="isVisible=true"></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.weight')" prop="weight">
            <el-input type="number" v-model="formData.weight"></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.operateStatus')" prop="status">
            <el-switch v-model="formData.status" :active-value=1 :inactive-value=0></el-switch>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">{{$t('table.cancel')}}</el-button>
          <el-button v-if="isCreate" type="primary" @click="createData">{{$t('table.confirm')}}</el-button>
          <el-button v-else type="primary" @click="updateData">{{$t('table.confirm')}}</el-button>
        </div>
      </el-dialog>
      <el-dialog  :close-on-click-modal="false"  :title="$t('table.iconList')" :visible.sync="isVisible">
        <icon-list v-if="isVisible" @chooseIcon="chooseIcon"></icon-list>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { permission } from "@/api/request";
import IconList from "@/components/icon";
export default {
  components: {
    IconList
  },
  data() {
    return {
      dialogFormVisible: false,
      listLoading: false,
      queryData: { type: undefined, page: 1, rows: 10 },
      formData: {
        url: undefined,
        name: undefined,
        parentId: undefined,
        type: undefined,
        method: undefined,
        icon: undefined,
        addAuto: false,
        status: 1
      },
      isCreate: false,
      statusOptions: [
        { key: 1, value: "status1" },
        { key: 0, value: "status0" }
      ],
      typeOptions: undefined,
      list: [],
      total: 1,
      rules: undefined,
      isVisible: false,
      parentMap: {}
    };
  },
  mounted() {
    this.setRulesAndType();
    this.handleList();
  },
  computed: {
    lang() {
      return this.$store.getters.language;
    }
  },
  watch: {
    lang() {
      this.setRulesAndType();
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    }
  },
  methods: {
    setRulesAndType() {
      this.typeOptions = [
        { key: 1, value: this.$t('common.directory') },
        { key: 2, value: this.$t('common.page') },
        { key: 3, value: this.$t('common.interface') }
      ];
      this.rules = {
        type: [{ required: true, message: this.$t('rules.perType') }],
        name: [{ required: true, message: this.$t('rules.perName') }]
      };
    },
    handleList() {
      this.listLoading = true;
      permission("get", this.queryData)
        .then(res => {
          this.list = res.data;
          this.total = res.total;
          this.listLoading = false;
          this.parentMap = res.extraData;
        })
        .catch(_ => (this.listLoading = false));
    },
    handleCreate() {
      this.isCreate = true;
      this.dialogFormVisible = true;
    },
    handleUpdate(row) {
      this.formData = Object.assign({}, row);
      this.dialogFormVisible = true;
    },
    closeDialog() {
      this.dialogFormVisible = false;
      this.isCreate = false;
      this.restFormData();
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleSizeChange(v) {
      this.queryData.rows = v;
      this.handleList();
    },
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.handleList();
    },
    updateData() {
      this.submitData("patch", this.$t("tips.update"));
    },
    createData() {
      this.submitData("post", this.$t("tips.add"));
    },
    handleDel(row, i) {
      this.$confirm(this.$t("tips.deleteConfirm"))
        .then(_ => {
          this.submitData("delete", this.$t("tips.delete"), row, i);
        })
        .catch(_ => _);
    },
    submitData(method, msg, param, i) {
      this.submitDataBefore(method != "delete", _ => {
        permission(method, param || this.formData).then(res => {
          this.$notify({
            title: this.$t("tips.success"),
            message: msg + ' ' + this.$t("tips.success"),
            type: "success",
            duration: 2000
          });
          i ? this.list.splice(i, 1) : this.handleList();
        });
        i ? "" : this.closeDialog();
      });
    },
    submitDataBefore(isCheck, cb) {
      isCheck ? this.$refs.dataForm.validate(_ => _ && cb()) : cb();
    },
    restFormData() {
      this.formData = {
        url: undefined,
        name: undefined,
        parentId: undefined,
        type: undefined,
        method: undefined,
        icon: undefined,
        addAuto: false,
        status: 1
      };
    },
    chooseIcon(v) {
      this.formData.icon = v;
      this.isVisible = false;
    },
    permissionTypeFmt(v) {
      return this.typeOptions.filter(e => e.key === v)[0].value;
    }
  }
};
</script>
