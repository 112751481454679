<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-date"></i> {{$t('navbar.setting')}}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('navbar.user')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div>
        <el-input @keyup.enter.native="handleList" style="width: 200px;" clearable class="filter-item" :placeholder="$t('table.name') + '/' + $t('table.phone')"
          v-model="queryData.name">
        </el-input>
        <el-select clearable class="filter-item" style="width: 130px" v-model="queryData.status" :placeholder="$t('table.status')">
          <el-option v-for="item in statusOptions" :key="item.key" :label="$t('common.' + item.value)" :value="item.key">
          </el-option>
        </el-select>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleList">{{$t('table.query')}}</el-button>
        <el-button class="filter-item" style="margin-left: 10px;" @click="handleCreate" type="primary" icon="el-icon-edit">{{$t('table.add')}}</el-button>
      </div>
      <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>
        <el-table-column align="center" :label="$t('table.userId')" min-width="65">
          <template slot-scope="scope">
            <span>{{scope.row.id}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" align="center" :label="$t('table.name')">
          <template slot-scope="scope">
            <span>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" align="center" :label="$t('table.phone')">
          <template slot-scope="scope">
            <span>{{scope.row.phone}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" align="center" :label="$t('table.status')">
          <template slot-scope="scope">
            <span :style="{color:scope.row.status==1?'#217bbd':'#e68a8a'}">{{scope.row.status | statusFmt}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" align="center" :label="$t('table.addTime')">
          <template slot-scope="scope">
            <span>{{scope.row.addTime}}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('table.operate')" min-width="230" class-name="small-padding fixed-width">
          <template slot-scope="scope" v-if="scope.row.type != 0">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">{{$t('table.edit')}}</el-button>
            <el-button type="danger" size="mini" @click="handleDel(scope.row, scope.$index)">{{$t('table.delete')}}</el-button>
            <el-button type="info" size="mini" @click="handleUpdatePwd(scope.row, scope.$index)">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryData.page" :page-sizes="[10,20,30,50]"
          :page-size="queryData.rows" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>

      <el-dialog :close-on-click-modal="false" :title="$t('table.edit')" :visible.sync="dialogFormVisible" :before-close="closeDialog">
        <el-form ref="dataForm" :model="formData" label-position="left" :rules="rules" label-width="120px" style='width: 400px; margin-left:50px;'>
          <el-form-item :label="$t('table.name')" prop="name" v-if="isCreate">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.password')" prop="password" v-if="isCreate">
            <el-input v-model="formData.password"></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.phone')" prop="phone">
            <el-input v-model="formData.phone" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.operateStatus')" prop="status">
            <el-switch v-model="formData.status" :active-value=1 :inactive-value=0></el-switch>
          </el-form-item>
          <el-form-item :label="$t('table.roles')" prop="roles" v-if="formData.name != 'admin' && rolesList.length > 0" v-show="formData.status === 1">
            <el-checkbox-group v-model="formData.roles">
              <el-checkbox v-for="role in rolesList" style="margin-left:0 !important;margin-right:30px;" :label="role.id" :key="role.id">{{role.info}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">{{$t('table.cancel')}}</el-button>
          <el-button v-if="isCreate" type="primary" @click="createData">{{$t('table.confirm')}}</el-button>
          <el-button v-else type="primary" @click="updateData">{{$t('table.confirm')}}</el-button>
        </div>
      </el-dialog>

      <el-dialog :close-on-click-modal="false" title="重置密码" :visible.sync="resetPwdVisible" :before-close="closeDialog">
        <el-form ref="dataForm" :model="formData" :rules="rules" label-position="left" label-width="120px">
          <el-form-item :label="$t('table.newPassword')" prop="newPassword">
            <el-input v-model="formData.newPassword"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">{{$t('table.cancel')}}</el-button>
          <el-button type="primary" @click="updateData">{{$t('table.confirm')}}</el-button>
        </div>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import { user } from "@/api/request";
import { forUser } from "@/api/request";
export default {
  data() {
    return {
      dialogFormVisible: false,
      resetPwdVisible: false,
      listLoading: false,
      queryData: { name, status, page: 1, rows: 10 },
      formData: {
        name: undefined,
        uid: undefined,
        password: undefined,
        phone: undefined,
        status: 1,
        roles: []
      },
      isCreate: false,
      statusOptions: [
        { key: 1, value: "status1" },
        { key: 0, value: "status0" }
      ],
      list: [],
      total: 1,
      rules: {},
      rolesList: []
    };
  },
  mounted() {
    this.setRules();
    this.handleList();
  },
  computed: {
    lang() {
      return this.$store.getters.language;
    }
  },
  watch: {
    lang() {
      this.setRules();
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    }
  },
  methods: {
    setRules() {
      this.rules = {
        name: [{ required: true, message: this.$t("rules.name") }],
        password: [{ required: true, message: this.$t("rules.password") }],
        newPassword: [{ required: true, message: this.$t("rules.password") }],
        phone: [
          {
            required: true,
            pattern: /^1[34578]\d{9}$/,
            message: this.$t("rules.phone")
          }
        ]
      };
    },
    handleList() {
      this.listLoading = true;
      user("get", this.queryData)
        .then(res => {
          this.list = res.data;
          this.total = res.total;
          this.listLoading = false;
        })
        .catch(_ => (this.listLoading = false));
    },
    handleCreate() {
      this.isCreate = true;
      this.dialogFormVisible = true;
      this.fetchRoleList();
    },
    fetchRoleList() {
      forUser("get", { cmd: "role" }).then(res => {
        this.rolesList = res.data;
      });
    },
    handleUpdate(row) {
      row.roles = [];
      this.rolesList = [];
      this.formData = Object.assign({}, row);
      if (this.formData.roleIds) {
        var ids = this.formData.roleIds.split(",");
        ids.forEach(e => {
          this.formData.roles.push(e * 1);
        });
      }
      this.dialogFormVisible = true;
      this.fetchRoleList();
    },
    handleUpdatePwd(row, i) {
      this.resetPwdVisible = true;
      this.formData.id = row.id;
    },
    closeDialog() {
      this.dialogFormVisible = false;
      this.resetPwdVisible = false;
      this.isCreate = false;
      this.restFormData();
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleSizeChange(v) {
      this.queryData.rows = v;
      this.handleList();
    },
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.handleList();
    },
    updateData() {
      this.submitData("patch", this.$t("tips.update"));
    },
    createData() {
      this.submitData("post", this.$t("tips.add"));
    },
    handleDel(row, i) {
      this.$confirm(this.$t("tips.deleteConfirm"))
        .then(_ => {
          this.submitData("delete", this.$t("tips.delete"), row, i);
        })
        .catch(_ => _);
    },
    submitData(method, msg, param, i) {
      this.submitDataBefore(method != "delete", _ => {
        user(method, param || this.formData).then(res => {
          this.$notify({
            title: this.$t("tips.success"),
            message: msg + ' ' + this.$t("tips.success"),
            type: "success",
            duration: 2000
          });
          this.handleList();
          this.closeDialog();
        });
      });
    },
    submitDataBefore(isCheck, cb) {
      isCheck ? this.$refs.dataForm.validate(_ => _ && cb()) : cb();
    },
    restFormData() {
      this.formData = {
        name: undefined,
        uid: undefined,
        password: undefined,
        phone: undefined,
        status: 1,
        roles: []
      };
    }
  }
};
</script>
