<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-date"></i> {{$t('navbar.setting')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('navbar.role')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div>
        <el-input @keyup.enter.native="handleList" style="width: 200px;" clearable class="filter-item" :placeholder="$t('table.roleName')" v-model="queryData.name">
        </el-input>
        <el-select clearable class="filter-item" style="width: 130px" v-model="queryData.status" :placeholder="$t('table.status')">
          <el-option v-for="item in statusOptions" :key="item.key" :label="$t('common.' + item.value)" :value="item.key">
          </el-option>
        </el-select>
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleList">{{$t('table.query')}}</el-button>
        <el-button class="filter-item" style="margin-left: 10px;" @click="handleCreate" type="primary" icon="el-icon-edit">{{$t('table.add')}}</el-button>
      </div>
      <el-table :data="list" v-loading="listLoading" :element-loading-text="$t('table.loading')" border highlight-current-row>
        <el-table-column align="center" :label="$t('table.roleName')">
          <template slot-scope="scope">
            <span>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('table.roleInfo')">
          <template slot-scope="scope">
            <span>{{scope.row.info}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" :label="$t('table.status')">
          <template slot-scope="scope">
            <span :style="{color:scope.row.status==1?'#217bbd':'#e68a8a'}">{{scope.row.status | statusFmt}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" align="center" :label="$t('table.addTime')">
          <template slot-scope="scope">
            <span>{{scope.row.addTime}}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('table.operate')" min-width="230" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">{{$t('table.edit')}}</el-button>
            <el-button v-if="scope.row.status!='deleted'" size="mini" type="danger" @click="handleDel(scope.row, scope.$index)">{{$t('table.delete')}}</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryData.page" :page-sizes="[10,20,30,50]" :page-size="queryData.rows" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>

      <el-dialog :close-on-click-modal="false" :title="$t('table.edit')" :visible.sync="dialogFormVisible" :before-close="closeDialog">
        <el-form ref="dataForm" :model="formData" label-position="left" :rules="rules" label-width="160px" style='width: 400px; margin-left:50px;'>
          <el-form-item :label="$t('table.roleName')" prop="name">
            <el-input v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.roleInfo')" prop="info">
            <el-input v-model="formData.info"></el-input>
          </el-form-item>
          <el-form-item :label="$t('table.operateStatus')" prop="status">
            <el-switch v-model="formData.status" :active-value=1 :inactive-value=0></el-switch>
          </el-form-item>
          <el-form-item :label="$t('table.dirPermissions')" prop="permissions" v-if="formData.name != 'admin'" v-show="formData.status === 1">
            <el-checkbox-group v-model="formData.permissions" style='width: 500px;'>
              <el-checkbox @change="pChange1($event, permission.id)" style="margin-left:0 !important;margin-right:30px;" v-for="permission in listFilter(1)" :label="permission.id" :key="permission.id">{{getPerName(permission)}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('table.pagePermissions')" prop="permissions" v-if="formData.name != 'admin'" v-show="formData.status === 1">
            <el-checkbox-group v-model="formData.permissions" style='width: 500px;'>
              <el-checkbox @change="pChange2($event, permission.id)" style="margin-left:0 !important;margin-right:30px;" v-for="permission in listFilter(2)" :label="permission.id" :key="permission.id">{{getPerName(permission)}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item :label="$t('table.interfacePermissions')" prop="permissions" v-if="formData.name != 'admin'" v-show="formData.status === 1">
            <el-checkbox-group v-model="formData.permissions" style='width: 500px;'>
              <el-checkbox @change="pChange3($event, permission.id)" style="margin-left:0 !important;margin-right:30px;" v-for="permission in listFilter(3)" :label="permission.id" :key="permission.id">{{getPerName(permission)}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">{{$t('table.cancel')}}</el-button>
          <el-button v-if="isCreate" type="primary" @click="createData">{{$t('table.confirm')}}</el-button>
          <el-button v-else type="primary" @click="updateData">{{$t('table.confirm')}}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { role } from "@/api/request";
import { forrole } from "@/api/request";
export default {
  data() {
    return {
      dialogFormVisible: false,
      listLoading: false,
      queryData: { name, status, page: 1, rows: 10 },
      formData: {
        name: undefined,
        id: undefined,
        info: undefined,
        status: 1,
        permissions: []
      },
      isCreate: false,
      statusOptions: [
        { key: 1, value: "status1" },
        { key: 0, value: "status0" }
      ],
      list: [],
      total: 1,
      rules: undefined,
      permissionList: []
    };
  },
  mounted() {
    this.setRules();
    this.handleList();
  },
  computed: {
    lang() {
      return this.$store.getters.language;
    }
  },
  watch: {
    lang() {
      this.setRules();
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    }
  },
  methods: {
    setRules() {
      this.rules = {
        name: [{ required: true, message: this.$t("rules.roleName") }],
        info: [{ required: true, message: this.$t("rules.roleInfo") }]
      };
    },
    handleList() {
      this.listLoading = true;
      role("get", this.queryData)
        .then(res => {
          this.list = res.data;
          this.total = res.total;
          this.listLoading = false;
        })
        .catch(_ => (this.listLoading = false));
    },
    fetchPermissionList() {
      forrole().then(res => {
        this.permissionList = res.data;
      });
    },
    handleCreate() {
      this.isCreate = true;
      this.dialogFormVisible = true;
      this.fetchPermissionList();
    },
    handleUpdate(row) {
      row.permissions = [];
      this.formData = Object.assign({}, row);
      if (row.permissionIds) {
        var ids = row.permissionIds.split(",");
        ids.forEach(e => {
          this.formData.permissions.push(e * 1);
        });
      }
      this.dialogFormVisible = true;
      this.fetchPermissionList();
    },
    closeDialog() {
      this.dialogFormVisible = false;
      this.isCreate = false;
      this.restFormData();
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleSizeChange(v) {
      this.queryData.rows = v;
      this.handleList();
    },
    handleCurrentChange(v) {
      this.queryData.page = v;
      this.handleList();
    },
    updateData() {
      this.submitData("patch", this.$t("tips.update"));
    },
    createData() {
      this.submitData("post", this.$t("tips.add"));
    },
    handleDel(row, i) {
      this.$confirm(this.$t("tips.deleteConfirm"))
        .then(_ => {
          this.submitData("delete", this.$t("tips.delete"), row, i);
        })
        .catch(_ => _);
    },
    submitData(method, msg, param, i) {
      this.submitDataBefore(method != "delete", _ => {
        role(method, param || this.formData).then(res => {
          this.$notify({
            title: this.$t("tips.success"),
            message: msg + " " + this.$t("tips.success"),
            type: "success",
            duration: 2000
          });
          i ? this.list.splice(i, 1) : this.handleList();
        });
        i ? "" : this.closeDialog();
      });
    },
    submitDataBefore(isCheck, cb) {
      isCheck ? this.$refs.dataForm.validate(_ => _ && cb()) : cb();
    },
    restFormData() {
      this.formData = {
        name: undefined,
        id: undefined,
        info: undefined,
        status: 1,
        permissions: []
      };
    },
    listFilter(v) {
      return this.permissionList.filter(e => e.type === v);
    },
    pChange1(v, data) {},
    pChange2(v, data) {
      this.permissionList.forEach(e => {
        if (e.parentId == data) {
          if (v) {
            this.formData.permissions.push(e.id);
          } else {
            let index = this.arrIndexOf(e.id);
            if(index >= 0){
              this.formData.permissions.splice(index, 1);
            }
          }
        }
      });
    },
    pChange3(v, data) {},
    getPerName(data) {
      let v = data.name;
      if (data.remark) {
        v += "(" + data.remark + ")";
      }
      return v;
    },
    arrIndexOf(v) {
      let index = -1;
      this.formData.permissions.forEach((e, i) => {
        if (e == v) {
          index = i;
        }
      });
      return index;
    }
  }
};
</script>

